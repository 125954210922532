<template>
  <div class="bg-color-white">
    <div class="adaptive-width pad-100-0">
      <div class="d-flex justify-space-between flex-wrap">
        <div class="d-flex flex-wrap">
          <!-- <ul class="mar-32">
            <li class="link-title">{{ $t('footer.sellerCenter') }}</li>
            <li
              class="link-text color-bla-5"
              v-for="(item, index) in sellerArr"
              :key="index"
              @click="openSite(item.siteUrl)"
            >{{ item.siteName }}</li>
          </ul> -->
          <div>
            <span class="link-title d-in-block">{{ $t('footer.contactUs') }}</span>
            <p class="link-textInfo font-w-bold color-bla-5">{{ $t('footer.hotline') }}</p>
            <p class="link-textInfo color-bla-5">+60189553339</p>
            <div class="mat-28">
              <!-- <p class="link-textInfo color-bla-5">{{ $t('footer.xiamenOffice') }}</p> -->
              <p class="link-textInfo color-bla-5" style="width: 300px">LINE2WORLD SDN BHD B-10-4 North Point Office, Mid Valley City. No. 1 Medan Syed Putra Utara 59200 Kuala Lumpur TEL</p>
            </div>
            <div class="mat-28">
              <!-- <img width="120" src="@/assets/image/home/monmall.jpg" /> -->
            </div>
          </div>
        </div>
        <div class="code-qur">
          <div>
            <p class="wx-bottom-title">GOGO2GO APP</p>
            <div class="d-flex flex-wrap">
              <div class="d-flex flex-direction-column justify-space-between">
                <div class="buttons d-flex im_next">
                  <img
                    class="fa fa-android"
                    src="@/assets/image/home/downApp.png"
                    width="34"
                    height="34"
                    alt
                  />
                  <div class="desc">
                    <a href="https://play.google.com/store/apps/details?id=com.niuke.gotogo" target="_blank">
                      <p>
                        <span>Android</span>
                        <br />on Google Play
                      </p>
                    </a>
                  </div>
                </div>
                <div class="buttons dark d-flex im_next">
                  <img
                    class="fa fa-apple"
                    src="@/assets/image/home/toGool.png"
                    width="34"
                    height="34"
                    alt
                  />
                  <div class="desc">
                    <a href="https://apps.apple.com/cn/app/gogo2go/id6449356849" target="_blank">
                      <p>
                        <span>Iphone</span>
                        <br />on App Store
                      </p>
                    </a>
                  </div>
                </div>
                <!-- <div class="mat-28">
                  <button class="mat-button d-flex align-items-center">
                    <img src="@/assets/image/home/ic_question.png" width="14" alt />
                    <span class="mar-left-5">{{ $t('footer.feedback') }}</span>
                  </button>
                </div> -->
              </div>
              <div style="display: flex;">
					<div style="text-align: center;">
						<div>Android</div>
						<img src="https://gogo2go-1253436512.cos.accelerate.myqcloud.com/Malaysia/takeout-admin/2023/06/cc0807a64d7b00119b1b05204bb5acaa.png" style="width: 125px;height: 125px"/>
					</div>
					<div style="text-align: center;">
						<div>Iphone</div>
						<img src="https://gogo2go-1253436512.cos.accelerate.myqcloud.com/Malaysia/takeout-admin/2023/06/be792f1f7a3031ea6621f721ae32de2a.png" style="width: 125px;height: 125px"/>
					</div>
			  </div>
            </div>
          </div>
          <!-- <div>
            <p class="wx-bottom-title">微信官方公众号</p>
            <div class="wx-bottom-QrCode"></div>
          </div>-->
        </div>
      </div>
      <el-divider></el-divider>
      <div class="d-flex justify-space-between color-bla-5">
        <!-- <a href="https://beian.miit.gov.cn/">
          <span>{{ $t('footer.areaNumber') }}</span>
        </a> -->
        <span>Copyright © 2023 GOGO2GO</span>
      </div>
    </div>
  </div>
</template>

<script>
import { siteList } from '@/api/base'
export default {
  name: 'bottomView',
  data() {
    return {
      sellerArr: [],
    }
  },
  mounted() {
    // this.getSiteList()
  },
  methods: {
    // 获取站点
    getSiteList() {
      siteList().then((res) => {
        this.sellerArr = res.data
      })
    },
    openSite(siteUrl) {
      window.open(siteUrl, '_blank')
    },
  },
}
</script>

<style scoped></style>
