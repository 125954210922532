
<template>
  <div id="header">
    <div
      class="header-wrap"
      :class="scrollTop > 0 && indexId !== 1 ? 'bg-color-white box-show-ccc color-000' : scrollTop > 0 && indexId === 1 ? 'bg-color-white' : ''"
    >
      <div class="header-width" :class="scrollTop > 0 && indexId === 1 ? 'border-bottom-ccc' : ''">
        <div class="dropdown d-flex justify-space-between w-100">
          <img
            src="https://gogo2go-1253436512.cos.accelerate.myqcloud.com/Malaysia/takeout-admin/2023/06/0d4e494e3fdcb424184ec4e70590c0f7.png"
            alt
            height="35px"
            style="margin-right:50px;"
            @click="clickImg"
          />
          <!-- <span style="color:#4285F7;font-size:22px;margin-left:8px;font-weight:bold;">MONMALL</span> -->
          <div class="header-nav">
            <el-menu
              :default-active="activeMenu"
              class="el-menu-vertical-demo"
              mode="horizontal"
              active-text-color="#4285F7"
              text-color="#000"
              @select="selectMenu"
              @open="handleOpen"
              @close="handleClose"
            >
              <template v-for="(item) in menuData" class="menu-box">
                <el-submenu v-if="item.childMenu" :index="item.pathValue" :key="item.id">
                  <template slot="title">
                    <span>{{ item.title }}</span>
                  </template>
                  <el-menu-item-group
                    class="child-box"
                    v-for="(ite) in item.childMenu"
                    :key="ite.id"
                  >
                    <el-menu-item :index="ite.pathValue">{{ ite.title }}</el-menu-item>
                  </el-menu-item-group>
                </el-submenu>
                <el-menu-item v-else :index="item.pathValue" :key="item.id">
                  <span style="font-size:16px" slot="title">{{ item.title }}</span>
                </el-menu-item>
              </template>
            </el-menu>
          </div>
          <button class="isBtn" @click="toSettled()">{{ $t('home.moveNow') }}</button>
          <el-dropdown class="mar-left-20" @command="handleCommandLanguage">
            <span class="el-dropdown-link">
              {{ $t('language.language') }}
              <i class="el-icon-caret-bottom el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="zh_CN">
                <div class="el-dropdown-width">{{ $t('language.chinese') }}</div>
              </el-dropdown-item>
              <el-dropdown-item command="en_US">
                <div class="el-dropdown-width">{{ $t('language.english') }}</div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <!-- 手机导航 -->
    <div class="header-nav-m">
      <div style="padding:0 10%; box-sizing: border-box; ">
        <!-- 导航栏 -->
        <div class="header-nav-m-menu">
          <div class="left-icon">
            <img src="https://gogo2go-1253436512.cos.accelerate.myqcloud.com/Malaysia/takeout-admin/2023/06/0d4e494e3fdcb424184ec4e70590c0f7.png" alt height="30px" />
            <span style="color:#4285F7;font-size:22px;margin-left:8px;font-weight:bold;">GOGO2GO</span>
          </div>
          <div>
            <i
              v-if="menuShow"
              class="el-icon-s-fold cursor-pointer"
              style="color:#4285F7;font-size:24px;"
              @click="openCloseMenu()"
            ></i>
            <i
              v-else
              class="el-icon-close cursor-pointer"
              style="color:#4285F7;font-size:24px;"
              @click="openCloseMenu()"
            ></i>
          </div>
        </div>
        <div v-if="!menuShow">
          <el-menu
            :default-active="activeMenu"
            :unique-opened="true"
            class="el-menu-vertical-demo"
            active-text-color="#4285F7"
            @select="selectMenu"
            @open="handleOpen"
            @close="handleClose"
          >
            <template v-for="(item) in menuData">
              <el-submenu v-if="item.childMenu" :index="item.pathValue" :key="item.id">
                <template slot="title">
                  <span>{{ item.title }}</span>
                </template>
                <el-menu-item-group v-for="(ite) in item.childMenu" :key="ite.id">
                  <el-menu-item :index="ite.pathValue">{{ ite.title }}</el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <el-menu-item v-else :index="item.pathValue" :key="item.id">
                <span slot="title">{{ item.title }}</span>
              </el-menu-item>
            </template>
          </el-menu>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'

export default {
  name: 'topView',
  data() {
    return {
      scrollTop: 0,
      indexId: 0,
      menuShow: true,
      // menuData: ,
      activeMenu: sessionStorage.getItem('activeMenuValue') || '/',
    }
  },
  computed: {
    menuData() {
      return [
        { id: '1', title: this.$t('menu.home'), pathValue: '/' },
        // { id: '2', title: this.$t('menu.news'), pathValue: '/new' },
        // { id: '3', title: '招商合作', pathValue: '1', childMenu: [{ id: '1', title: '卖家入驻', pathValue: '/cooperate' }] },
        { id: '4', title: this.$t('menu.policy'), pathValue: '/policy' },
        // { id: '6', title: this.$t('帮助中心'), pathValue: '/helpCenter' },
        { id: '5', title: this.$t('menu.about'), pathValue: '/about' },
        // { id: '5', title: '关于我们', pathValue: '2', childMenu: [{ id: '1', title: '了解我们', pathValue: '/about' }] },
      ]
    },
  },
  mounted() {
    this.activeMenu = this.$route.path
    window.addEventListener('scroll', this.windowScroll)
  },
  methods: {
    // 切换语言
    handleCommandLanguage(command) {
      this.$router.go(0)
      if (command === 'zh_CN') {
        Cookies.set('language', 'zh_CN')
        this.$i18n.locale = 'zh_CN'
      } else {
        Cookies.set('language', 'en_US')
        this.$i18n.locale = 'en_US'
      }
    },
    windowScroll() {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      // eslint-disable-next-line no-empty
      if (this.indexId === 1 && this.scrollTop > 520) {
      }
    },

    openCloseMenu() {
      this.menuShow = !this.menuShow
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    },
    toSettled() {
      this.$router.push({ path: '/settled' })
    },
    selectMenu(key) {
      this.$router.push({ path: key })
      sessionStorage.setItem('activeMenuValue', key)
      this.activeMenu = key
      this.menuShow = !this.menuShow
    },
    clickImg() {
      this.$router.push({ path: '/' })
      this.activeMenu = '/'
    },
  },
}
</script>
<style scoped>
.header-nav-m {
  display: none;
}
.el-menu {
  border-right: none;
  background: rgba(0, 0, 0, 0);
}
.middle-menu {
  padding: 5px 40px;
  box-sizing: border-box;
}
.header-nav .el-menu-item {
  padding: 0;
  margin: 0 40px;
}
.el-menu--horizontal > .is-active {
  background: rgba(0, 0, 0, 0) !important;
  color: #4285f7 !important;
}
.el-menu--horizontal >>> .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal >>> .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal >>> .el-submenu .el-submenu__title:hover {
  background: rgba(0, 0, 0, 0) !important;
  color: #4285f7 !important;
}
.el-menu--horizontal:hover .el-menu--horizontal i {
  color: #4285f7 !important;
}
.el-menu--horizontal >>> .el-submenu:hover {
  background: rgba(0, 0, 0, 0) !important;
  color: #4285f7 !important;
}
.el-menu.el-menu--horizontal {
  border: none;
}
.el-menu--horizontal > .el-menu-item {
  /* border-bottom: none; */
}
.el-menu--horizontal > .el-menu-item.is-active {
  /* border-bottom: none; */
}
.is-active >>> .el-submenu__title {
  /* border-bottom-color: rgba(0, 0, 0, 0) !important; */
}

.child-box {
  display: inline-block !important;
  vertical-align: middle !important;
  width: 110px;
  text-align: center;
  border-top: #4285f7 4px solid;
}

@media screen and (max-width: 997px) {
  #header .header-nav-m {
    display: block !important;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 999999;
    padding: 10px 0;
    background: #fff;
  }
  .header-wrap {
    display: none;
  }
}
.header-nav-m-menu {
  display: flex;
  justify-content: space-between;
}
.left-icon {
  display: flex;
  align-items: center;
}
</style>
