<template>
  <div>
    <top-menu></top-menu>
    <router-view/>
    <bottom-view></bottom-view>
    <GoTop></GoTop>
  </div>
</template>
<script>
import topMenu from '../components/topMenu.vue'
import bottomView from '../components/bottomView.vue'
/* 回到顶部 */
import GoTop from '../components/GoTop.vue'
export default {
  components: {
    topMenu,
    bottomView,
    GoTop
  }
}
</script>
