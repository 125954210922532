<template>
  <div id="GoTop" @click="GoTop()">
    <i class="el-icon-upload2" style="color:#8e9ca9;font-size:24px;"></i>
  </div>
</template>
<script>
export default {
  name: "GoTop",
  data() {
    return {
      flag: false
    };
  },
  methods: {
    GoTop() {
      (function smoothscroll() {
        var currentScroll =
          document.documentElement.scrollTop || document.body.scrollTop;
        if (currentScroll > 0) {
          window.requestAnimationFrame(smoothscroll);
          window.scrollTo(0, currentScroll - currentScroll / 10);
        }
      })();
    }
  }
};
</script>
<style scoped>
#GoTop {
  width: 50px;
  height: 50px;
  position: fixed;
  right: 15px;
  bottom: 100px;
  z-index: 99999999;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dee6f1;
  text-align: center;
  line-height: 50px;
}
</style>
